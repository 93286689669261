import {token, analyzeToken, initUser} from '../user';
import {initIdeSocket} from '../network';
import { forEach, replace, set } from 'lodash';
import * as socketManager from '../network/manager';
import { getActiveDesktop, setActiveDesktop } from '../storage';
import {searchModule} from '../api';
import { handleExtenedUrl } from './extenedurl';
import { injectFont } from '../font';

const checkAuth = () =>
{
  return Boolean(token);
};

const initIframe = () =>
{
  const iframe = document.createElement('iframe');
  iframe.id = 'codigger-main';
  iframe.height = '100%';
  iframe.width = '100%';
  const body = document.getElementsByTagName('body');
  body[0].appendChild(iframe);
  iframe.addEventListener('load', () =>
  {
    injectFont(iframe?.contentWindow);
  });
  return iframe;
};

const urlMap = [
  {
    'path':'/web/portal/',
    'url':'/web/portal.sys/'
  },
  // {
  //   'path':'/web/desktop/',
  //   'multipleDesktop':true,
  // },
  // {
  //   'path':'/onecloud/side/',
  //   'url':'/onecloud/side.sys/'
  // }
];

const getDesktopUrl = async () =>
{
  const {moduleVersion} = await searchModule({'moduleName':'desktop-essential-fancy'});
  return `/essential/desktop/fancy.sys/${moduleVersion}`;
};

// const resolveDesktopUrl = async (type, desktopInstance) =>
// {
//   const desktopMap = {
//     Fancy: {
//       moduleName: 'desktop-essential-fancy',
//       url: '/essential/desktop/fancy.sys/'
//     },
//     Rich: {
//       moduleName: 'desktop-mymuse-rich',
//       url: '/mymuse/desktop/rich.sys/'
//     },
//     Extra: {
//       moduleName: 'desktop-cosmos-extra',
//       url: '/cosmos/desktop/extra.sys/'
//     }
//   };
//   try {
//     const desktop = desktopMap[type] || desktopMap['Fancy'];
//     const {moduleVersion} = await searchModule({'moduleName': desktop.moduleName});
//     return `${desktop.url}${moduleVersion}/`;
//   } catch (error) {
//     console.error('Resolve desktop url error:', error);
//   }
// };


const getIframeUrl = async (url) =>
{
  const params = url.split('/');
  const modulePathname = url.split(/\?|\#/)[0];

  if(params.length == 2 && /([a-zA-Z0-9]{2,40}-){2,5}([a-zA-Z0-9])+$/.test(modulePathname))
  {
    return `/web/portal.sys/module${modulePathname}`;
  }
  // no token
  if(!checkAuth())
  {
    if (document.location.pathname.includes('mymuse/meeting'))
    {
      history.pushState('/app-mymuse-meeting', 'Codigger', '/app-mymuse-meeting');
      return '/web/portal.sys/module/app-mymuse-meeting?loginCallback=meetinghome';
    }
    else if (document.location.href.includes('appId=Meeting'))
    {
      const paramsStr = document.location.href.split('?')[1];
      history.pushState('/app-mymuse-meeting', 'Codigger', '/app-mymuse-meeting');
      return `/web/portal.sys/module/app-mymuse-meeting?loginCallback=desktopMeeting&${paramsStr}`;
    }
    history.pushState('/web/portal/', 'Codigger', '/web/portal/');
    // portal
    if(url.indexOf('/web/portal/') > -1)
    {
      return replace(url, '/web/portal/', '/web/portal.sys/');
    }
    return '/web/portal.sys/';
  }
  if(!url)
  {
    return '/web/portal.sys/';
  }
  for(let i = 0; i < urlMap.length; i++)
  {
    const value = urlMap[i];
    if(url.indexOf(value.path) > -1)
    {
      // if(value.multipleDesktop)
      // {
      //   const activeDesktop = getActiveDesktop();
      //   const desktopPath =  await resolveDesktopUrl(activeDesktop.type, activeDesktop.desktopInstance);
      //   return replace(url, value.path, desktopPath);
      // }
      return replace(url, value.path, value.url);
    }
  }
  if(url.indexOf('/web/desktop') !== -1) {
    const {moduleVersion} = await searchModule({'moduleName': 'web-onecloud-foundation'});
    return `/web/foundation.sys/${moduleVersion}/`;
  }
  return handleExtenedUrl(url);
  
  // console.log('can not find module');
  // return await getDesktopUrl();
};

const changeIframe = async (url = '') =>
{
  let myUrl = await getIframeUrl(url);
  if(!myUrl) return;
  const baseUrl = process.env.VUE_APP_PUBLISH_ENV === 'development' ? 'https://codigger.onecloud.cn' : '';
  return new Promise((resolve) =>
  {
    const hasIframe = document.getElementById('codigger-main');
    if(hasIframe)
    {
      hasIframe.remove();
    }
    const target = initIframe();
    if(myUrl.indexOf('http') < 0)
    {
      myUrl = baseUrl + myUrl;
    }
    if(target.src !== myUrl)
    {
      socketManager.closeAll();
      target.src = myUrl;
    }
    resolve();
  });
};

export const push = (path) =>
{
  if(!path)
  {
    return;
  }
  try
  {
    if(window.top === window.self)
    {
      history.pushState(path, 'Codigger', path);
      changeIframe(path);
    }
    else
    {
      const baseUrl = process.env.VUE_APP_PUBLISH_ENV === 'development' ? 'https://codigger.onecloud.cn' : '';
      location.href = baseUrl + '/web/portal/';
    }
  }
  catch (error)
  {
    console.error('push', error);
  }
};

export const changeDesktop = async (setting = {}) =>
{
  // const { type, desktopInstance } = setting;
  // const src = await resolveDesktopUrl(type, desktopInstance);
  const $iframe = document.getElementById('codigger-main');
  $iframe.contentWindow.location.reload();
  // $iframe.src = src;
  // return src;
};

export const login = async (path, myToken) =>
{
  try
  {
    analyzeToken(myToken);
    await initUser(myToken);
    initIdeSocket();
  }
  catch (error)
  {
    console.error('login error', error);
  }
  push(path);
};

export const initRouter = () =>
{
  return new Promise((resolve) =>
  {
    const indexPath = localStorage.getItem('CodiggerIndexPath');
    changeIframe(location.pathname === '/' ? indexPath || '' : location.pathname + location.search);
    window.addEventListener('popstate', () =>
    {
      changeIframe(location.pathname + location.search);
    });
    resolve();
  });
};


