import httpClient from '@/api/http';
import { createUuid } from '@/utils';

// 首字母大写
function capitalizeFirstLetter (str)
{
  return str.toLowerCase().replace(/\b[a-z]/g, (match) =>
  {
    return match.toUpperCase();
  });
}

function errorLog (isUpdate)
{
  console.table([
    {
      key: 'appName',
      require: true,
      descrition: '应用名称',
      remark: ''
    },
    {
      key: 'state',
      require: true,
      descrition: '运行状态',
      remark: 'starting:启动中,running:运行中,stopped:已关闭'
    },
    {
      key: 'instanceId',
      require: isUpdate,
      descrition: '实例id',
      remark: '更新必须，非更新创建'
    },
    {
      key: 'desktop',
      require: false,
      descrition: '所属桌面名称',
      remark: ''
    },
    {
      key: 'parentInstanceId',
      require: false,
      descrition: '父级',
      remark: ''
    },
    {
      key: 'sessionId',
      require: false,
      descrition: 'gateway连接的connectionId',
      remark: '关闭窗口断连清除数据用'
    },
    {
      key: 'mudemId',
      require: false,
      descrition: '',
      remark: ''
    },
    {
      key: 'display',
      require: false,
      descrition: '其他信息',
      remark: ''
    }
  ]);
}

class CodiggerInstance
{
  constructor()
  {
    this.initBaseWait = null; // connectionId没有时 等待事件
    this.createDesktopQueue = new Map(); // 创建队列
    this.desktopInstanceIds = new Map(); // 桌面实例集
    this.applicationsCache = [];
    // 基座实例
    this._baseInstanceId = 0;
    // desktop的connectionId
    this._connectionId = 0;
  }

  // 桌面moduleame
  get deskModuleName()
  {
    return {
      base: 'web-onecloud-desktop-base',
      fancy: 'desktop-essential-fancybeta',
      rich: 'desktop-mymuse-richbeta'
    };
  }

  // app状态
  get APP_STATE()
  {
    return {
      STARTING: 'starting', // 启动中
      RUNNING: 'running', // 运行中
      STOPPED: 'stopped', // 已关闭
    };
  }

  get LEVEL()
  {
    return {
      TOP: 0,
      BASE: 1,
      DESKTOP: 2,
      APP: 3,
      LOOK: 4
    };
  }

  get SOURCE()
  {
    return {
      BROWSER: 1,
      MUDEM: 2
    };
  }

  get connectionId()
  {
    return this._connectionId;
  }

  set connectionId(val)
  {
    this._connectionId = val;
    this.initBaseWait?.();
    this.initBaseWait = null;
  }

  // 基座实例Id
  get baseInstanceId()
  {
    return this._baseInstanceId;
  }

  set baseInstanceId(val)
  {
    this._baseInstanceId = val;
  }

  // 获取桌面实例Id
  getDesktopInstanceId(desktop)
  {
    return this.desktopInstanceIds.get(desktop)?.instanceId || '';
  }

  // 获取应用实例Id
  getAppInstanceId({
    appModuleName,
    workstationId,
    sessionId = this.connectionId
  })
  {
    if (!appModuleName)
    {
      return Promise.reject('Incomplete information: appModuleName');
    }
    return this.applicationsCache.find((v) =>
      v.appName === appModuleName &&
      (!workstationId || v.workstationId === workstationId) &&
      v.sessionId === sessionId
    )?.instanceId;
  }

  // 获取指定桌面数据
  getDesktopData(desktop)
  {
    return this.desktopInstanceIds.get(desktop);
  }

  // 保存桌面数据
  setDesktopData(desktop, data)
  {
    this.desktopInstanceIds.set(desktop, data);
  }

  // 通过appModuleName解析应用信息
  getModuleInfo(moduleName)
  {
    if (!moduleName) return {};
    const names = moduleName.split('-');
    let naturalName = '-';
    if (names.length > 2)
    {
      const name = names.slice(2);
      naturalName = name.map((v) => capitalizeFirstLetter(v)).join(' ');
    }
    return {
      naturalName,
      enNaturalName: naturalName,
      developer: names[1] || '-'
    };
  }

  // 初始化基座instanceId
  async initBase(callback)
  {
    // 没有connectionId则等待
    if (!this.connectionId)
    {
      return this.initQueue = () =>
      {
        this.initBase(callback);
      };
    }
    const params = {
      appName: this.deskModuleName.base,
      state: 'running',
      parentInstanceId: 0,
      desktopConnectionId: this.connectionId,
      display: JSON.stringify({
        ...this.getModuleInfo(this.deskModuleName.base),
        level: this.LEVEL.BASE
      })
    };
    await this.ackReport(params, (instanceId) =>
    {
      // 保存基座instanceId
      this.baseInstanceId = instanceId;
      callback?.({ ...params, instanceId });
    });

    if (this.createDesktopQueue.size)
    {
      Array.from(this.createDesktopQueue).forEach((v) =>
      {
        const deleteFn = v[1]?.();
        deleteFn();
      })
    }
  }

  // 创建desktopInstanceId
  async createDesktopInstanceId(desktop)
  {
    if (!this.baseInstanceId)
    {
      const key = createUuid();
      this.createDesktopQueue.set(key, () =>
      {
        this.createDesktopInstanceId(desktop);
        return () =>
        {
          this.createDesktopQueue.delete(key);
        };
      });
      return;
    }
    const appName = desktop?.toLowerCase().includes('fancy') ? this.deskModuleName.fancy : this.deskModuleName.rich;
    const params = {
      appName,
      desktop: '',
      state: 'running',
      parentInstanceId: this.baseInstanceId || 0,
      display: JSON.stringify({
        naturalName: desktop || '',
        enNaturalName: desktop || '',
        developer: this.getModuleInfo(appName).developer || '',
        level: this.LEVEL.DESKTOP
      })
    };
    await this.ackReport(params, (instanceId) =>
    {
      // 保存桌面instanceId
      this.setDesktopData(desktop, { ...params, instanceId });
    });
  }

  // 销毁desktopInstanceId
  async destroyDesktopInstanceId(desktop)
  {
    const params = this.getDesktopData(desktop);
    if (params)
    {
      await this.ackReport({
        ...params,
        state: this.APP_STATE.STOPPED
      });
    }
    
  }

  // 上报数据
  report(data)
  {
    return httpClient.post({
      data,
      url: `${process.env.VUE_APP_PORTAL}/user/desktop/appInstance/save`,
      oauth: true,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      successCodes: 200
    });
  };

  /**
   * 带重试的上报
   * @param {string} params.appName moudleName 必须
   * @param {string} params.desktop desktopName 必须
   * @param {string} params.state state 必须
   * @param {number} params.parentInstanceId parentInstanceId app通过desktop自动获取
   * @param {string} params.sessionId sessionId 没有则自动获取
   * @param {number} params.instanceId instanceId 没有则为新增
   * @param {number} params.mudemId mudemId 非必须
   * @param {string} params.display display信息 非必须
   * @param {*} callback 
   * @returns 
   */
  async ackReport(params, callback)
  {
    let abort = false;
    let instanceId = '';
    for (let i = 0; i < 3; i++)
    {
      if (!abort)
      {
        params = {
          appName: params.appName || '',
          bootPeerPid: '',
          connectionId: '',
          desktop: params.desktop || '',
          display: params.display || JSON.stringify({}),
          instanceId: params.instanceId || '',
          mudemId: params.mudemId || '',
          parentInstanceId: params.parentInstanceId || this.getDesktopData(params.desktop)?.instanceId || 0,
          peers: '',
          projectId: '',
          sessionId: params?.sessionId || this.connectionId || '',
          source: this.SOURCE.BROWSER, // 1浏览器，2mudem
          state: params.state || '', // staring, running, stopped
          username: top?.codigger?.user?.info?.username
        };
        instanceId = await this.report(params).catch(() => undefined);
        if (typeof instanceId !== 'undefined')
        {
          callback?.(instanceId);
          break;
        }
      }
      // 中断停止
      else
      {
        break;
      }
    }
    if (!instanceId && params.state === this.APP_STATE.STARTING)
    {
      callback?.(false);
    }
    return () =>
    {
      abort = ture;
    };
  }

  createInstance(params = {})
  {
    if (!params.appName || !params.state)
    {
      errorLog();
      return Promise.reject('Incomplete information');
    }
    return new Promise((resolve) =>
    {
      this.ackReport(params, resolve);
    });
  }

  updateInstace(params = {})
  {
    if (!params.appName || !params.state || !params.instanceId)
    {
      errorLog(true);
      return Promise.reject('Incomplete information');
    }
    return new Promise((resolve) =>
    {
      this.ackReport(params, resolve);
    });
  }

  transData(data)
  {
    let display = {};
      try
      {
        display = JSON.parse(data.display) || {};
      }
      catch (e)
      {
        console.log(`${data.appName || data.projectId}的display信息转换失败，不处理`);
      }
      const projectInfo = this.getModuleInfo(data.projectId);
      return {
        instanceId: data.instanceId || '',
        parentInstanceId: data.parentInstanceId || 0,
        host: display.host || '',
        moduleName: data.appName || data.projectId || '',
        naturalName: display.naturalName || projectInfo.naturalName,
        enNaturalName: display.enNaturalName || projectInfo.enNaturalName,
        developer: display.developer || projectInfo.developer,
        description: display.description || '',
        workstationId: data.source === this.SOURCE.BROWSER ? data.mudemId : '',
        mudemId: data.source === this.SOURCE.MUDEM ? data.mudemId : '', // source=1 浏览器，=2 mudem
        source: data.source,
        desktop: data.desktop || '',
        state: data.state,
        desktopConnectionId: data.sessionId,
        level: display.level || this.LEVEL.TOP
      }; 
  }

  // 获取应用列表
  async getApplications()
  {
    this.applicationsCache = await httpClient.get({
      url: `${process.env.VUE_APP_PORTAL}/user/desktop/appInstance/get`,
      oauth: true,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }).catch(() => this.applicationsCache);
    return this.applicationsCache.map((v) =>
    {
      return this.transData(v);
    });
  };
}

const codiggerInstance = new CodiggerInstance();

export default codiggerInstance;
