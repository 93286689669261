/*
 * @Author: laibin
 * @email: laisan8848@163.com
 * @Date: 2024-08-07 20:29:17
 * @LastEditors: laibin
 * @LastEditTime: 2024-08-07 20:29:17
 * @Description: file content
 */
import '../alert/alert.css';
import queryString from 'query-string';
import {push, changeDesktop, login} from '../router';
import fullScreen from '../utils/fullScreen';
import {setScreenResolution} from '../utils/screen';
import codiggerBroadcast from '../utils/broadcast/index';
import sendUserNotice from '../utils/sendUserNotice';
import codiggerInstance from '../codiggerInstance';
import {set} from 'lodash';
import * as i18n from '../i18n';
import * as storage from '../storage';
import api from '../api';
import {VimcallSocket} from '../network/vimcall';
import {DtermSocket} from '../network/dterm';
import { initDesktopPool } from '../desktopPool';
import { injectFont } from '../font';
import { notice } from '../notice';

export let user = {};
export let token = null;

export const logout = () =>
{
  localStorage.removeItem('oauth-token');
  push('/web/portal/?redirect='+location.pathname + location.search);
};


export const analyzeToken = (myToken) =>
{
  if(!myToken)
  {
    return;
  }
  const base64Data = myToken.split('.');
  const userData = JSON.parse(decodeURIComponent(escape(window.atob(base64Data[1].replace(/-/g, '+').replace(/_/g, '/')))));
  user = userData;
  set(window, 'top.codigger.user.info', user);
  token = myToken;
  localStorage.setItem('oauth-token', myToken);
  return userData;
};

export const initUser = (myToken) =>
{
  try
  {
    set(window, 'top.codigger.router.push', push);
    set(window, 'top.codigger.router.changeDesktop', changeDesktop);
    set(window, 'top.codigger.router.login', login);
    set(window, 'top.codigger.utils.fullScreen', fullScreen);
    set(window, 'top.codigger.i18n', i18n);
    set(window, 'top.codigger.font.inject', injectFont);
  }
  catch (error)
  {
    console.error('initUser', error);
  }
  
  return new Promise((resolve, reject) =>
  {
    const queryParams = queryString.parse(location.search);
    const oauthToken = queryParams?.jwtToken || myToken || localStorage.getItem('oauth-token');
    if(!oauthToken)
    {
      resolve();
      return;
    }
    token = oauthToken;
    localStorage.setItem('oauth-token', oauthToken);
    try
    {
      set(window, 'top.codigger.user.info', user);
      set(window, 'top.codigger.user.logout', logout);
      set(window, 'top.codigger.utils.setScreenResolution', setScreenResolution);
      set(window, 'top.codigger.utils.broadcast', codiggerBroadcast);
      set(window, 'top.codigger.utils.sendUserNotice', sendUserNotice);
      set(window, 'top.codigger.codiggerInstance', codiggerInstance);
      set(window, 'top.codigger.notice', notice);
      set(window, 'top.codigger.api', api);
      set(window, 'top.codigger.network.VimcallSocket', VimcallSocket);
      set(window, 'top.codigger.network.DtermSocket', DtermSocket);
      set(window, 'top.codigger.storage', storage);
      initDesktopPool();
    }
    catch (error)
    {
      console.error('initUser', error);
    }
    resolve(analyzeToken(oauthToken));
    return;
  });
};