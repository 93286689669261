import httpClient from '@/api/http';
import { createUuid } from '@/utils';

// 异常提醒
function errorLog ()
{
  const embed = typeof window.$codigger !== 'undefined';
  console.table([
    {
      key: 'username',
      require: true,
      descrition: '接收者codigger用户名',
      remark: ''
    },
    {
      key: 'titleZh',
      require: true,
      descrition: '中文标题',
      remark: '默认显示：消息'
    },
    {
      key: 'titleEn',
      require: true,
      descrition: '英文标题',
      remark: '默认显示：Information'
    },
    {
      key: 'moduleName',
      require: !embed,
      descrition: '应用名称',
      remark: '例如app-mymuse-meeting'
    },
    {
      key: 'instanceId',
      require: !embed,
      descrition: '实例ID',
      remark: embed ? '自动获取' : '通过top.codigger.codiggerInstance.createInstance创建后传入'
    },
    {
      key: 'peerName',
      require: !embed,
      descrition: '节点名称',
      remark: embed ? '自动获取' : '待提供方法'
    },
    {
      key: 'notificationType',
      require: false,
      descrition: '消息类型',
      remark: '默认取module名称'
    },
    {
      key: 'buttonNameZh',
      require: false,
      descrition: '按钮中文名',
      remark: '为空不显示按钮'
    },
    {
      key: 'buttonNameEn',
      require: false,
      descrition: '按钮英文名',
      remark: '为空不显示按钮'
    },
    {
      key: 'buttonParams',
      require: false,
      descrition: '按钮参数(JSON或标准的JSON字符串)',
      remark: ''
    },
    {
      key: 'buttonType',
      require: false,
      descrition: '按钮类型',
      remark: '目前只有1'
    },
    {
      key: 'headerZh',
      require: false,
      descrition: '中文内容，内容区头部',
      remark: 'header,content,footer存在一个'
    },
    {
      key: 'headerEn',
      require: false,
      descrition: '英文内容，内容区头部',
      remark: 'header,content,footer存在一个'
    },
    {
      key: 'contentZh',
      require: false,
      descrition: '中文内容',
      remark: 'header,content,footer存在一个'
    },
    {
      key: 'contentEn',
      require: false,
      descrition: '英文内容',
      remark: 'header,content,footer存在一个'
    },
    {
      key: 'footerZh',
      require: false,
      descrition: '中文内容，内容区底部',
      remark: 'header,content,footer存在一个'
    },
    {
      key: 'footerEn',
      require: false,
      descrition: '英文内容，内容区底部',
      remark: 'header,content,footer存在一个'
    },
    {
      key: 'level',
      require: false,
      descrition: '应用层级',
      remark: '默认6'
    },
    {
      key: 'messageLevel',
      require: false,
      descrition: '消息类型',
      remark: '0:debug,1:Info,2:Alter,3:Error'
    },
    {
      key: 'operateIn',
      require: false,
      descrition: '来源：SK,Store,Rose=1;APP=2，默认2',
      remark: '1:Kernel Space,2:User Space,其他=值'
    },
    {
      key: 'time',
      require: false,
      descrition: '服务时间',
      remark: ''
    },
  ]);
}


// 发送通知
function postSendUserNotice (data)
{
  return httpClient.post({
    url: `${process.env.VUE_APP_PORTAL}/notification/sendUserNotice`,
    data,
    oauth: true,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    successCodes: 200
  });
}

function sendUserNotice (params = {})
{
  const embed = typeof window.$codigger !== 'undefined';
  if (
    !params.username ||
    !embed && !params.moduleName ||
    !embed && typeof params.instanceId === 'undefined' ||
    !(params.titleZh && params.titleEn) ||
    !(params.headerZh && params.headerEn) && !(params.contentZh && params.contentEn) && !(params.footerZh && params.footerEn)
  )
  {
    errorLog();
    return Promise.reject('Incomplete information');
  }
  const messageId = `${params.moduleName}-${createUuid()}-${new Date().getTime()}`;
  const operateIn = params.operateIn === 1 ? 'Kernel Space' : 'User Space';
  const names = params.moduleName.split('-');
  const notificationType = names.slice(2).join(' ');
  const data = {
    buttonNameZh: '',
    buttonNameEn: '',
    buttonType: 1,
    command: 'notice',
    titleZh: '消息',
    titleEn: 'Message',
    headerZh: '',
    headerEn: '',
    contentZh: '',
    contentEn: '',
    footerZh: '',
    footerEn: '',
    messageId,
    messageLevel: 1,
    moduleName: '', // 应用moduleName
    instanceId: '', // 实例ID
    operateIn: typeof params.operateIn === 'string' ? params.operateIn : operateIn,
    notificationType,
    level: 6,
    ...params,
    ...params.buttonParams && {
      buttonParams: Object.prototype.toString.call(params.buttonParams) === '[object Object]' ? JSON.stringify(params.buttonParams) : params.buttonParams
    }
  };
  return postSendUserNotice(data);
}

export default sendUserNotice;

