import { Icon, templateIcon } from './icon'
import codiggerBroadcast from '../utils/broadcast/index';
import { createUuid } from '../utils';
class Notice {
  constructor () {
    this.defaultMessage = {
      icon: 'success',
      message: 'Success',
      id: null,
      duration: 3000,
      height: 44,
      type: 'info',
      callbackBtn: '',
      callback: () =>
      {
        // 1.用来调用app自身的函数，来执行app内部通知的逻辑，例如meeting的join通知
        // 2.用来调用notice SDK的notifyNotice函数，实现app发消息通知desktop的notice app的能力。
      }
    };
    this.broadcast = new codiggerBroadcast();
    this.messageList = [];
  }

  removeMessage (target)
  {
    const idx = this.messageList.findIndex((v) => v[0].isEqualNode(target));
    if (idx > -1) {
      this.messageList[idx][1].remove();
      this.messageList.splice(idx, 1);
      const index = this.messageList.findIndex((v) => v[0].isEqualNode(target));
      if(index > -1)
      {
        this.messageList[index][1].start();
      }
    }
  }

  stopMessageTime (target)
  {
    const arr = this.messageList.filter((v) => v[0].isEqualNode(target));
    arr.forEach((item, index) =>
    {
      if(index !== 0)
      {
        item[1].stop();
        item[1].setZindex(-1);
      }
    }) 
  }

  createNoticeDom (config, closeKey)
  {
    let countdown = Math.ceil(config.duration / 1000);
    if(countdown > 99) countdown = 99;
    const htmlString = `
      <style  type="text/css">
      .titlebar-notice {
        color: #FFFFFF; 
        width: 1024px; 
        height: ${config.height}px; 
        position: absolute; 
        top: 0; left: 0; 
        z-index: 999; 
        display: flex; 
        align-items: center;
        padding-left: 12px;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #1A5A87 30%);
        user-select: none;
      }
      .titlebar-notice-container {
        display: flex; 
        align-items: center;
        animation: slideInFromLeft 0.5s ease-out forwards;
      }
      @keyframes slideInFromLeft {
        from {
          transform: translateX(-50px);
          opactity: 0;
        }
        to {
          transform: translateX(0);
          opactity: 1;
        }
      }
      .titlebar-notice.info {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #1A5A87 30%);
      }
      .titlebar-notice.error {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #B21657 30%);
      }
      .titlebar-notice.alert {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #C26421 30%);
      }
      .titlebar-notice.success {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #2B7235 30%);
      }
      .titlebar-notice .icon {
        width: 24px;
        height: 24px;
      }
      .titlebar-notice .msg {
        max-width: 540px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-left: 12px;
        margin-right: 16px;
        font-size: 16px;
        color: #FFFFFF;
      }
      .titlebar-notice .btn {
        height: 28px;
        background: rgba(0, 0, 0, 0.24);
        box-sizing: border-box;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.9);
        margin-right: 20px;
        text-align: center;
        padding: 0 5px 0 12px;
        cursor: pointer;
      }
      .titlebar-notice .btn .btnText {
        min-width: 30px;
        display: inline-block;
        text-align: center;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .titlebar-notice .btn img{
        width: 16px;
        height: 16px;
      }
      .titlebar-notice .notice-close {
        width: 26px;
        height: 22px;
        box-sizing: border-box;
        padding-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: url(${templateIcon.closeBox});
        background-size: 26px 22px;
        position: relative;
      }
      .titlebar-notice .notice-close .countdown{
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
      }
      .titlebar-notice .notice-close .close{
        display: block;
        width: 26px;
        height: 22px;
        background: url(${templateIcon.closeIcon});
        background-size: 26px 22px;
        position: absolute;
        top: 0;
        left: 0;
      }
      </style>
      <div class="titlebar-notice-container">
        <img class="icon" src="${Icon[config.icon] || Icon['complete']}" />
        <div class="msg">${config.message}</div>
        <div class="btn btn-${closeKey}
        " style="display: ${config.callbackBtn ? 'flex' : 'none'}">
          <span class="btnText">${config.callbackBtn}</span>
          <img src="${templateIcon.detailIcon}" />
        </div>
        <div class="notice-close notice-close-${closeKey}">
          <div class="countdown countdown-${closeKey}" style="display: none;">${countdown}</div>
          <div class="close close-${closeKey}"></div>
        </div>
      </div>
    `;
    return htmlString;
  }

  createTitleNotice (config) {
    const target = config.target;
    // this.removeMessage(target);
    const closeKey = createUuid();
    const noticeDiv = document.createElement('div');
    noticeDiv.classList.add(`titlebar-notice`);
    noticeDiv.classList.add(`titlebar-notice-${closeKey}`);
    noticeDiv.classList.add(config.type);
    noticeDiv.innerHTML = this.createNoticeDom(config, closeKey);

    let interval = null;
    let timer = null;
    let totalTime = 0;
    let status = 0;
    let waitTime = 3;

    target.appendChild(noticeDiv);

    target.querySelector(`.titlebar-notice-${closeKey}`).addEventListener('mouseover', function (e) {
      target.querySelector(`.countdown-${closeKey}`).style.display = 'none';
      target.querySelector(`.close-${closeKey}`).style.display = 'block';
      stop();
    });
    target.querySelector(`.titlebar-notice-${closeKey}`).addEventListener('mouseout', function (e) {
      start();
    });
  
    target.querySelector(`.notice-close-${closeKey}`).addEventListener('mouseover', function (e) {
      target.querySelector(`.countdown-${closeKey}`).style.display = 'none';
      target.querySelector(`.close-${closeKey}`).style.display = 'block';
    });

    target.querySelector(`.notice-close-${closeKey}`).addEventListener('mouseout', function (e) {
      target.querySelector(`.close-${closeKey}`).style.display = 'none';
      target.querySelector(`.countdown-${closeKey}`).style.display = 'block';
    });

    target.querySelector(`.notice-close-${closeKey}`).addEventListener('click', () => {
      if(timer) clearInterval(timer);
      if(interval) clearInterval(interval);
      this.removeMessage(target);
    });

    target.querySelector(`.btn-${closeKey}`).addEventListener('click', () => {
      config.callback();
    });

    const handleCountDown = () =>
    {
      if(timer) clearInterval(timer);
      if(target.querySelector(`.close-${closeKey}`)) {
        target.querySelector(`.close-${closeKey}`).style.display = 'block';
      }
      if(target.querySelector(`.countdown-${closeKey}`)) {
        target.querySelector(`.countdown-${closeKey}`).style.display = 'none';
      }

      timer = setInterval(() =>
      {
        if(interval) clearInterval(interval);
        if(status === 1) return;
        waitTime --;
        if(waitTime > 0) return;
        clearInterval(timer);
        totalTime = Math.ceil(config.duration / 1000);
        if(totalTime > 99) totalTime = 99;
        if(target.querySelector(`.close-${closeKey}`)) {
          target.querySelector(`.close-${closeKey}`).style.display = 'none';
        }
        if(target.querySelector(`.countdown-${closeKey}`)) {
          target.querySelector(`.countdown-${closeKey}`).innerHTML = totalTime;
          target.querySelector(`.countdown-${closeKey}`).style.display = 'block';
        }
        interval = setInterval(() =>
        {
          if(status === 1) return;
          if(totalTime > 0)
          {
            totalTime --;
            if(target.querySelector(`.countdown-${closeKey}`))
            {
              target.querySelector(`.countdown-${closeKey}`).innerHTML = totalTime;
            }
            if(totalTime === 0) 
            {
              clearInterval(interval);
              clearInterval(timer);
              waitTime = 3;
              this.removeMessage(target);
            }
          }
        }, 1000);
      }, 1000);
    };

    handleCountDown();

    const remove = () =>
    {
      // if(interval) clearInterval(interval);
      // if(timer) clearInterval(timer);
      target.removeChild(noticeDiv);
    };

    const start = () =>
    {
      status = 0;
      waitTime = 3;
      totalTime = Math.ceil(config.duration / 1000);
      handleCountDown();
      setZindex(999);
    };

    const stop = () =>
    {
      status = 1;
    };

    const setZindex = (zIndex) =>
    {
      target.querySelector(`.titlebar-notice-${closeKey}`).style.zIndex = zIndex;
    };

    this.messageList.unshift([target, {remove, start, stop, setZindex}]);
    this.stopMessageTime(target);

    return () => {
      this.removeMessage(target);
    }
  }

  message (config) {
    if(!(Object.prototype.toString.call(config.target).includes('[object HTML'))) {
      throw new Error('Please use a legal Dom');
    }
    if(typeof config.duration !== 'number')
    {
      throw new Error('duration type is a number');
    }
    if(typeof config.message !== 'string')
    {
      throw new Error('message type is a string and needs to specify content');
    }
    if(!config.message || !(typeof config.message === 'string' && config.message.trim()))
    {
      throw new Error('message needs to specify content');
    }
    return this.createTitleNotice({...this.defaultMessage, ...config});
  }

  success (config) {
    return this.message({type: 'success', message: 'Success', duration: 3000, icon:'complete', ...config});
  }

  error (config) {
    return this.message({type: 'error', message: 'Failed', duration: 3000, icon:'attention', ...config});
  }

  alert (config) {
    return this.message({type: 'alert', duration: 10000, icon:'message',  ...config});
  }

  info (config) {
    return this.message({type: 'info', icon:'message', duration: 5000, ...config});
  }

  notifyNotice (messageId) {
    // 具体实现逻辑要和notice的接收消息逻辑做对应
    console.log(messageId);
    this.broadcast.emit('notice_message', {
      type: 'openNotice',
      data: {
        messageId
      }
    });
  }
}

export const notice = new Notice();